import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2110427e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = { class: "block font-medium mb-1" }
const _hoisted_3 = {
  key: 0,
  class: "text-red-600"
}
const _hoisted_4 = {
  key: 0,
  class: "text-red-500 text-sm mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.required)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_InputNumber, {
      modelValue: _ctx.numberValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.numberValue) = $event)),
      placeholder: _ctx.placeholder,
      suffix: _ctx.suffix,
      prefix: _ctx.prefix,
      class: _normalizeClass(['w-full', { 'border-red-500': _ctx.hasError(_ctx.v$, _ctx.path) }])
    }, null, 8, ["modelValue", "placeholder", "suffix", "prefix", "class"]),
    (_ctx.hasError(_ctx.v$, _ctx.path))
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.errorMessage(_ctx.v$, _ctx.path)), 1))
      : _createCommentVNode("", true)
  ]))
}