import { Validation } from '@vuelidate/core'

export const getFieldValidation = (v$: Validation, path: string) => {
  if (!v$ || !path) return null 

  return path.split('.').reduce((acc, part) => acc?.[part], v$) 
}

export const hasError = (v$: Validation, path: string): boolean => {
  const field = getFieldValidation(v$, path)
  return !!field && field.$dirty && field.$invalid
}

export const errorMessage = (v$: Validation, path: string): string | null => {
  const field = getFieldValidation(v$, path)

  if (!field || !field.$dirty) return null 

  switch (true) {
    case field.required?.$invalid:
      return 'Field ini wajib diisi.'
    case field.minLength?.$invalid:
      return `Minimal ${field.minLength.$params.min} karakter.`
    case field.maxLength?.$invalid:
      return `Maksimal ${field.maxLength.$params.max} karakter.`
    case field.numeric?.$invalid:
      return 'Harus berupa angka.'
    default:
      return null
  }
}
