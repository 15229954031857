import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f5c72f68"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = { class: "block font-medium mb-1" }
const _hoisted_3 = {
  key: 0,
  class: "text-red-600"
}
const _hoisted_4 = ["placeholder", "value"]
const _hoisted_5 = {
  key: 0,
  class: "text-red-500 text-sm mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DatePicker = _resolveComponent("DatePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.required)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_DatePicker, {
      modelValue: _ctx.dateValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateValue) = $event))
    }, {
      default: _withCtx(({ inputValue, inputEvents }) => [
        _createElementVNode("input", _mergeProps({
          class: ["p-inputtext p-component w-full border rounded-md p-2", { 'border-red-500': _ctx.hasError(_ctx.v$, _ctx.path) }],
          placeholder: _ctx.placeholder,
          value: inputValue
        }, _toHandlers(inputEvents)), null, 16, _hoisted_4)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (_ctx.hasError(_ctx.v$, _ctx.path))
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.errorMessage(_ctx.v$, _ctx.path)), 1))
      : _createCommentVNode("", true)
  ]))
}