import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container-hjp-confirm-modal" }
const _hoisted_2 = { class: "header flex" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "container-img" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "title" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "container-btn-action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Dialog, {
      showHeader: false,
      visible: _ctx.showModal,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showModal) = $event)),
      breakpoints: { '960px': '75vw', '640px': '90vw' },
      style: { width: '30vw' },
      modal: true,
      contentClass: "wrap-content-hjp-confirm-modal"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("i", {
            class: "pi pi-times cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeDialog()))
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", { src: _ctx.imgContent }, null, 8, _hoisted_5)
          ]),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.title), 1),
          (_ctx.subTitleHtml)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                innerHTML: _ctx.subTitle,
                class: "sub-title"
              }, null, 8, _hoisted_7))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "sub-title",
                innerHTML: _ctx.subTitle
              }, null, 8, _hoisted_8)),
          (_ctx.slot['additionalContent'])
            ? _renderSlot(_ctx.$slots, "additionalContent", { key: 2 })
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_Button, {
              type: "submit",
              class: "btn-primary mb-2",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSubmit()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.btnSubmitTitle), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Button, {
              class: "btn-back mr-2",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeDialog()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.btnBackTitle), 1)
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 3
    }, 8, ["visible"])
  ]))
}