
  import { defineComponent, ref, computed } from 'vue'
  import { DatePicker } from 'v-calendar'
  import { hasError, errorMessage } from '@/utils/helpers/validation'
  import { Validation } from '@vuelidate/core'
  
  export default defineComponent({
    components: {
      DatePicker,
    },
    props: {
      label: {
        type: String,
        required: true,
      },
      modelValue: {
        type: [Date, String], 
        required: true,
      },
      placeholder: {
        type: String,
        default: 'Select a date',
      },
      path: {
        type: String,
        required: true,
      },
      v$: {
        type: Object as () => Validation,
        required: true,
      },
      required: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const dateValue = computed({
        get() {
          return props.modelValue 
        },
        set(value: Date | string) {
          emit('update:modelValue', value) 
        },
      })
  
      return {
        dateValue,
        hasError,
        errorMessage,
      }
    },
  })
  