
    import { defineComponent, computed } from 'vue'
    import { useStore } from 'vuex'
    
    export default defineComponent({
        name: 'VerticalHeading',
        props: {
        title: {
            type: String,
            required: true,
        },
        tag: {
            type: String,
            default: 'h4',
        },
        },
        setup() {
            const store = useStore()
        
            const headingClass = computed(() => (store.state.switchEnv === 'staging' ? 'secondary' : 'primary'))

            return {
                headingClass,
            }
        },
    })
  