
import { defineComponent, computed } from 'vue'
import InputText from 'primevue/inputtext'
import InputGroup from 'primevue/inputgroup'
import InputGroupAddon from 'primevue/inputgroupaddon'
import { hasError, errorMessage } from '@/utils/helpers/validation'
import { Validation } from '@vuelidate/core'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    InputText,
    InputGroup,
    InputGroupAddon,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    addon: {
      type: String,
      default: 'addon',
    },
    path: {
      type: String,
      default: '', 
    },
    v$: {
      type: Object as () => Validation,
      default: null, 
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const store = useStore()

    const addonClass = computed(() => {
      const envClassMap = {
        staging: 'p-inputgroup-addon-secondary',
        production: 'p-inputgroup-addon-primary',
      } as const 
      const env = store.state.switchEnv as keyof typeof envClassMap
      return envClassMap[env] || ''
    })

    const stringValue = computed({
      get() {
        return String(props.modelValue)
      },
      set(value: string) {
        emit('update:modelValue', value)
      },
    })

    return {
      stringValue,
      addonClass,
      hasError,
      errorMessage,
    }
  },
})
