import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45192bd5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = { class: "block font-medium mb-1" }
const _hoisted_3 = {
  key: 0,
  class: "text-red-600"
}
const _hoisted_4 = ["placeholder", "rows", "cols"]
const _hoisted_5 = {
  key: 0,
  class: "text-red-500 text-sm mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.required)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
        : _createCommentVNode("", true)
    ]),
    _withDirectives(_createElementVNode("textarea", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.stringValue) = $event)),
      placeholder: _ctx.placeholder,
      rows: _ctx.rows,
      cols: _ctx.cols,
      class: _normalizeClass(['w-full border rounded-md p-2', { 'border-red-500': _ctx.hasError(_ctx.v$, _ctx.path) }])
    }, null, 10, _hoisted_4), [
      [_vModelText, _ctx.stringValue]
    ]),
    (_ctx.hasError(_ctx.v$, _ctx.path))
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.errorMessage(_ctx.v$, _ctx.path)), 1))
      : _createCommentVNode("", true)
  ]))
}