
    import { defineComponent, computed } from 'vue'
    import InputNumber from 'primevue/inputnumber'
    import { hasError, errorMessage } from '@/utils/helpers/validation'
    import { Validation } from '@vuelidate/core'
    
    export default defineComponent({
        components: {
        InputNumber,
        },
        props: {
        label: {
            type: String,
            required: true,
        },
        modelValue: {
            type: [Number, String], 
            required: true,
        },
        placeholder: {
            type: String,
            default: '',
        },
        suffix: {
            type: String,
            default: '',
        },
        prefix: {
            type: String,
            default: '',
        },
        path: {
            type: String,
            default: '', 
        },
        v$: {
            type: Object as () => Validation,
            default: null, 
        },    
        required: {
            type: Boolean,
            default: false,
        },
        },
        emits: ['update:modelValue'],
        setup(props, { emit }) {
        const numberValue = computed({
            get() {
                return Number(props.modelValue) || 0 
            },
            set(value: number) {
                emit('update:modelValue', value) 
            }
        })
    
        return {
            numberValue,
            hasError,
            errorMessage,
        }
        },
    })
  